import React from "react"

class HubspotBrochureDownload extends React.Component {
  componentDidMount(props) {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: `${this.props.portalID}`,
          formId: "8bd80897-d39e-4a60-ab7e-9d639450cd41", //also you can do fromID: `${this.props.formID}`
          target: "#hubspotForm",
        })
      }
    })
  }

  render() {
    return (
      <div className="form">
        <div id="hubspotForm"></div>
      </div>
    )
  }
}

export default HubspotBrochureDownload
