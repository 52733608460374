import React from "react"
import { Link } from "gatsby"
import { RiArrowDropRightLine } from "react-icons/Ri"
import HubspotContactForm from "../components/hubspot"
import HubspotBrochureDownload from "../components/hubspot"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function contactPage() {
  return (
    <Layout>
      <Seo title="お問い合わせ" />
      <ul className="page_list">
        <li>
          <Link className="page_link" to="/">
            Home
          </Link>
        </li>
        <li>
          <RiArrowDropRightLine className="page_arrow" />
        </li>
        <li>お問い合わせ</li>
      </ul>
      <div className="contact_txtbox">
        <h1 className="contact_title">お問い合わせ</h1>
        <p className="contact_titles">contact</p>

        <HubspotBrochureDownload portalID="20197061" />
      </div>
    </Layout>
  )
}
